import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';

export default function Logo(props) {
  return (
    <>
      <Helmet>
        <Link to="../../static/myAvatar.png"/>
      </Helmet>
      <main>
        <h1>CB.com GameDev</h1>
      </main>
    </>
  )
}
